import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    code:'',
    token:'',
    openid:'',
    userinfo:{},
    redirect:''
  },
  mutations: {
    changeCode(state,payload){
      state.code = payload
    },
    changeToken(state,payload){
      state.token = payload
    },
    changeOpenId(state,payload){
      state.openid = payload
    },
    changeUserInfo(state,payload){
      state.userinfo = payload
    },
    changeRedirect(state,payload){
      state.redirect = payload
    },
  },
  actions: {
  },
  modules: {
  }
})
