import Vue from 'vue'
import VueRouter from 'vue-router'
import { getToken } from "@/utils/auth";

Vue.use(VueRouter)

const whiteList = ["/","/login", "/auth-redirect", "/alipay", "/register"];

const routes = [
  {
    path: '/',
    name: 'index',
    meta:{title:'首页'},
    component: () => import('@/views/chaxun/index.vue')
  },
  // {
  //   path:'/login',
  //   name:'Login',
  //   component:()=>import('@/views/Login/index.vue')
  // },
  // {
  //   path:'/register',
  //   name:'register',
  //   component:()=>import('@/views/Login/register.vue')
  // },
  // { path: "*", name:'404', component: ()=>import('@/views/404.vue') },
  // {
  //   path: '/',
  //   name: 'Home',
  //   meta:{title:'首页'},
  //   component: () => import('@/views/Index/index.vue')
  // },
  // {
  //   path: '/device',
  //   name: 'Device',
  //   meta:{title:'设备管理'},
  //   component: () => import('@/views/Index/device.vue')
  // },
  // {
  //   path: '/store',
  //   name: 'Store',
  //   meta:{title:'门店管理'},
  //   component: () => import('@/views/Index/store.vue')
  // },
  // {
  //   path: '/goods/:id?',
  //   name: 'Goods',
  //   meta:{title:'商品管理'},
  //   component: () => import('@/views/Index/goods.vue')
  // },
  // {
  //   path: '/data',
  //   name: 'Data',
  //   meta:{title:'经营统计'},
  //   component: () => import('@/views/Index/report.vue')
  // },
  // {
  //   path: '/order',
  //   name: 'Order',
  //   meta:{title:'订单管理'},
  //   component: () => import('@/views/Index/order.vue')
  // },
  // {
  //   path: '/orderinfo/:id?',
  //   name: 'OrderInfo',
  //   meta:{title:'订单详情'},
  //   component: () => import('@/views/Index/orderInfo.vue')
  // },
  // {
  //   path: '/user',
  //   name: 'User',
  //   meta:{title:'个人中心'},
  //   component: () => import('@/views/Index/user.vue')
  // },

  // {
  //   path: '/device',
  //   name: 'Device',
  //   meta:{title:'设备管理'},
  //   component: () => import('../views/Device.vue')
  // },
  // {
  //   path: '/deviceadd',
  //   name: 'DeviceAdd',
  //   meta:{title:'添加新设备'},
  //   component: () => import('../views/DeviceAdd.vue')
  // },
  // {
  //   path: '/deviceinfo/:number',
  //   name: 'DeviceInfo',
  //   meta:{title:'设备详情'},
  //   component: () => import('../views/DeviceInfo.vue')
  // },
  // {
  //   path: '/history/:number?',
  //   name: 'History',
  //   meta:{title:'上报记录'},
  //   component: () => import('../views/History.vue')
  // },
  // {
  //   path: '/bind',
  //   name: 'Bind',
  //   meta:{title:'个人中心'},
  //   component: () => import('../views/Bind.vue')
  // },{
  //   path:'/getCode',
  //   name:'getCode',
  //   component:()=>import('../views/getCode.vue')
  // }

  // {
  //     path: '/alipay',
  //     name: 'Alipay',
  //     meta:{title:'支付宝'},
  //     component: () => import('../views/Alipay/index.vue')
  // }
]

const router = new VueRouter({
  // mode: "history",//去除地址栏#
  routes
})

router.beforeEach((to,from,next)=>{
  // console.log('====To=====',to)
  // console.log('====From=====',from)
  if(getToken()){
    document.body.scrollTop = 0
    document.documentElement.scrollTop = 0
    window.pageYOffset = 0
    next();
  }else{
    //登录
    if (whiteList.indexOf(to.path) !== -1) {
      // 在免登录白名单，直接进入
      next();
    } else {
      next(`/login?redirect=${to.fullPath}`); // 否则全部重定向到登录页
    }
  }
})
export default router
