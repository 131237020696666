import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

//ele-ui
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);


//mint-ui
import MintUI from 'mint-ui'
import 'mint-ui/lib/style.css'
Vue.use(MintUI)

//vant
import Vant from 'vant';
import 'vant/lib/index.css';

Vue.use(Vant);

//触底
import VueScroller from 'vue-scroller';
Vue.use(VueScroller);

// 页面显示标题
import VueWechatTitle from 'vue-wechat-title'
Vue.use(VueWechatTitle)

//Less
import less from 'less'
Vue.use(less)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
