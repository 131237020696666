<template>
  <div id="app">
    <!-- <keep-alive :exclude="excludePages"> -->
      <router-view v-wechat-title="$route.meta.title" />
    <!-- </keep-alive> -->
    <!-- <van-tabbar v-model="active" active-color="#ff1234" inactive-color="#000" v-if="$route.name != 'Login'">
        <van-tabbar-item icon="wap-home-o" to="/">首页</van-tabbar-item>
        <van-tabbar-item icon="user-o" to="/user">我的</van-tabbar-item>
    </van-tabbar> -->
  </div>
</template>
<script>
export default{
  data(){
    return{
      active:0,
      excludePages:['Goods','OrderInfo']
    }
  }
}
</script>
<style lang="less">
body{
  margin:0;
  padding: 0;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // text-align: center;
  color: #2c3e50;
  margin: 0;
	padding: 0;
}
#nav {
  padding: 30px;
  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
